const { userAgent } = window.navigator;

/**
 * Check whether the browser is Internet Explorer.
 *
 * @return {boolean}
 */
export const isIe = () => userAgent.indexOf('Trident/') > 0;

/**
 * Check whether the browser is Edge.
 *
 * @return {boolean}
 */
export const isEdge = () => userAgent.indexOf('Edge/') > 0;
